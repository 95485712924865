import { z } from 'zod';

import { ManagerIdSchema, OrganizationIdSchema, SurferIdSchema } from './ids';

// Roles are an Auth0 concept however for compatibility we automatically give non-Auth0 users the manager or surfer role if they have a managerId or surferId respectively
export const RoleSchema = z.enum(['manager', 'surfer', 'internal']);
export type Role = z.infer<typeof RoleSchema>;

// These properties will exist on any authenticated Request
export const AuthedUserSchema = z.object({
  organizationId: OrganizationIdSchema,
  surferId: SurferIdSchema.optional(),
  managerId: ManagerIdSchema.optional(),
  roles: RoleSchema.array().min(1),
  isDialpad: z.boolean(),
});
export type AuthedUser = z.infer<typeof AuthedUserSchema>;

export const UserSchema = AuthedUserSchema.merge(
  z.object({
    id: z.string(), // Dialpad user id or Auth0 user id
    firstName: z.string(),
    lastName: z.string(),
    email: z.string().email(),
    imageUrl: z.string().url().optional(),
    organizationName: z.string(),
    isProxied: z.boolean(),
    impersonatorUserId: z.string().optional(),
  }),
);
export type User = z.infer<typeof UserSchema>;

export const GetLoggedInResponseSchema = z.object({
  loggedIn: z.boolean(),
  user: UserSchema.optional(),
});
export type GetLoggedInResponse = z.infer<typeof GetLoggedInResponseSchema>;
