import { User } from 'shared-types';

export function getUserName(user?: User) {
  const name = user?.firstName
    ? user.lastName
      ? `${user.firstName.trim()} ${user.lastName.trim()}`
      : user.firstName
    : user?.email
      ? user.email
      : '?';

  return name;
}
