import { useEffect, useMemo, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

import { useAuth } from '../../providers/AuthProvider';
import { constructServerUrl } from '../../queries/api';
import { CenteredSpinner } from '../../shared-components/CenteredSpinner';
import { getOAuthState } from '../../utils/auth';
import { AuthErrorPage } from './AuthErrorPage';

export const DialpadRedirect = () => {
  const [error, setError] = useState(null);
  const { authMethod, isAuthenticated, logout } = useAuth();
  const history = useHistory();
  const location = useLocation();

  const query = useMemo(() => new URLSearchParams(location.search), [location.search]);

  useEffect(() => {
    const fetchAndRedirect = async () => {
      try {
        const response = await fetch(constructServerUrl('auth/url'), {
          method: 'GET',
        });
        const data = await response.json();

        if (!data?.url) return;

        const url = new URL(data.url);

        // When a Dialpad employee impersonates a user from CSR the URL will contain a login hint that will be the impersonated user's Dialpad user id
        const loginHint = query.get('login_hint');
        if (loginHint) {
          url.searchParams.append('login_hint', loginHint);
        }

        const stateParam = getOAuthState();
        url.searchParams.append('state', stateParam);

        const redirectUrl = url.toString();

        if (isAuthenticated) {
          if (authMethod === 'dialpad') {
            history.push('/');
          } else if (authMethod === 'auth0') {
            await logout();
            window.location.href = redirectUrl;
          }
        } else {
          window.location.href = redirectUrl;
        }
      } catch (error) {
        setError(error);
      }
    };

    fetchAndRedirect();
  }, [isAuthenticated, authMethod, logout, query, history]);

  if (error) return <AuthErrorPage error={error} />;

  return <CenteredSpinner />;
};
