import { SystemStyleObject } from '@chakra-ui/theme-tools';

const surfboardColors: SystemStyleObject = {
  white: '#FFFFFF',
  gray: {
    5: '#FAFAFF',
    10: '#F7F7FA',
    20: '#E7E7EA',
    30: '#929296',
    40: '#646468',
    50: '#27262B',
  },
  purple: {
    10: '#F3F3FF',
    20: '#BCB1FF',
    30: '#7C52FF', // Dialtone var(--dt-color-purple-400) temporary till all color changes are done
    40: '#3B1A97',
    50: '#221155',
  },
  yellow: {
    10: '#FFFAEA',
    20: '#FFEFB7',
    30: '#FFD873',
    40: '#FFC700', // primary
    50: '#665000',
  },
  teal: {
    10: '#EBFBF7',
    20: '#C5E9DE',
    30: '#55BCB6',
    40: '#297D7D',
    50: '#1D4947',
  },
  green: {
    10: '#F1F9F0',
    20: '#BAE2B6',
    30: '#74C56D',
    50: '#21491D',
  },
  orange: {
    10: '#FFF5EB',
    20: '#FFCB99',
    30: '#FF9C3C',
    50: '#663200',
  },
  pink: {
    10: '#FFEBF5',
    20: '#FFADD8',
    30: '#F769B2',
    50: '#660035',
  },
  berry: {
    10: '#F7EBFF',
    20: '#D899FF',
    30: '#AD38F5',
    50: '#3F0066',
  },
  olive: {
    10: '#F9F9F0',
    20: '#E2E0B6',
    30: '#CAC779',
    50: '#49481D',
  },
  royalBlue: {
    10: '#EBEBFF',
    20: '#999DFF',
    30: '#3840FF',
    50: '#000466',
  },
  blue: {
    10: '#EBF3FF',
    20: '#99C3FF',
    30: '#599DFF',
    50: '#002966',
  },
  beige: {
    10: '#F6F2EF',
    20: '#DAC9BE',
    30: '#BD9F8B',
    50: '#413025',
  },
  red: {
    10: '#FDE7E7',
    20: '#F7A1A1',
    30: '#F37070',
    50: '#5E0808',
    error: '#E31E03',
  },
  inputBorderColor: '#E7E7E7',
  success: {
    30: '#428500',
    40: '#264D00',
  },
  error: {
    30: '#E31E03',
    40: '#971402',
  },
};

const dialtoneColors: SystemStyleObject = {
  black: {
    100: 'var(--dt-color-black-100)',
    200: 'var(--dt-color-black-200)',
    300: 'var(--dt-color-black-300)',
    400: 'var(--dt-color-black-400)',
    500: 'var(--dt-color-black-500)',
    600: 'var(--dt-color-black-600)',
    700: 'var(--dt-color-black-700)',
    800: 'var(--dt-color-black-800)',
    900: 'var(--dt-color-black-900)',
  },
  purple: {
    100: 'var(--dt-color-purple-100)',
    200: 'var(--dt-color-purple-200)',
    300: 'var(--dt-color-purple-300)',
    400: 'var(--dt-color-purple-400)',
    500: 'var(--dt-color-purple-500)',
    600: 'var(--dt-color-purple-600)',
  },
  blue: {
    100: 'var(--dt-color-blue-100)',
    200: 'var(--dt-color-blue-200)',
    300: 'var(--dt-color-blue-300)',
    400: 'var(--dt-color-blue-400)',
    500: 'var(--dt-color-blue-500)',
  },
  magenta: {
    100: 'var(--dt-color-magenta-100)',
    200: 'var(--dt-color-magenta-200)',
    300: 'var(--dt-color-magenta-300)',
    400: 'var(--dt-color-magenta-400)',
    500: 'var(--dt-color-magenta-500)',
  },
  gold: {
    100: 'var(--dt-color-gold-100)',
    200: 'var(--dt-color-gold-200)',
    300: 'var(--dt-color-gold-300)',
    400: 'var(--dt-color-gold-400)',
    500: 'var(--dt-color-gold-500)',
  },
  green: {
    100: 'var(--dt-color-green-100)',
    200: 'var(--dt-color-green-200)',
    300: 'var(--dt-color-green-300)',
    400: 'var(--dt-color-green-400)',
    500: 'var(--dt-color-green-500)',
  },
  red: {
    100: 'var(--dt-color-red-100)',
    200: 'var(--dt-color-red-200)',
    300: 'var(--dt-color-red-300)',
    400: 'var(--dt-color-red-400)',
    500: 'var(--dt-color-red-500)',
  },
  tan: {
    100: 'var(--dt-color-tan-100)',
    200: 'var(--dt-color-tan-200)',
    300: 'var(--dt-color-tan-300)',
    400: 'var(--dt-color-tan-400)',
    500: 'var(--dt-color-tan-500)',
  },
  // The below colors do not yet exist in Dialtone
  // Sunset
  orange: {
    100: '#FFF5EB',
    200: '#FFCB99',
    300: '#FF9C3C',
    500: '#663200',
  },
  // Bondi
  teal: {
    100: '#EBFBF7',
    200: '#C5E9DE',
    300: '#55BCB6',
    400: '#297D7D',
    500: '#1D4947',
  },
  // Mangrove
  olive: {
    100: '#F9F9F0',
    200: '#E2E0B6',
    300: '#CAC779',
    500: '#49481D',
  },
  // Ocean
  royalBlue: {
    100: '#EBEBFF',
    200: '#999DFF',
    300: '#3840FF',
    500: '#000466',
  },
  // Urchin
  berry: {
    100: '#F7EBFF',
    200: '#D899FF',
    300: '#AD38F5',
    500: '#3F0066',
  },
};

// Temporary functions while both color objects exist
function isObject(item: any) {
  return item && typeof item === 'object' && !Array.isArray(item);
}

function deepMerge(target: any, ...sources: any[]): SystemStyleObject {
  if (!sources.length) return target;
  const source = sources.shift();

  if (isObject(target) && isObject(source)) {
    for (const key in source) {
      if (isObject(source[key])) {
        if (!target[key]) Object.assign(target, { [key]: {} });
        deepMerge(target[key], source[key]);
      } else {
        Object.assign(target, { [key]: source[key] });
      }
    }
  }
  return deepMerge(target, ...sources);
}

export const colors = deepMerge(surfboardColors, dialtoneColors);
