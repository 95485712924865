// eslint-disable-next-line no-restricted-imports
import { Auth0ContextInterface, Auth0Provider, useAuth0 } from '@auth0/auth0-react';
import {
  ReactNode,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { GetLoggedInResponse, GetLoggedInResponseSchema, User } from 'shared-types';

import { UnauthorizedError, constructServerUrl } from '../queries/api';
import { SharedRoutes } from '../routes/appRoutes';
import { validateOAuthState } from '../utils/auth';

type AuthMethod = 'auth0' | 'dialpad';

interface AuthState {
  error?: Error;
  isAuthenticated: boolean;
  isLoading: boolean;
  user?: User;
  authMethod?: AuthMethod;
}

export interface AuthContextInterface extends AuthState {
  // Shared methods
  logout: () => Promise<void>;
  // Auth0 methods
  auth0: {
    getAccessTokenSilently: Auth0ContextInterface['getAccessTokenSilently'];
    loginWithRedirect: Auth0ContextInterface['loginWithRedirect'];
  };
  // Session methods
  session: {
    checkLoginState: () => Promise<GetLoggedInResponse | undefined>;
    exchangeAuthCodeForToken: () => Promise<void>;
  };
}

const initialAuthState: AuthState = {
  isAuthenticated: false,
  isLoading: true,
};

const AuthContext = createContext<AuthContextInterface | null>(null);

const CustomAuthProvider = ({ children }: { children: ReactNode }) => {
  const [state, setState] = useState<AuthState>(initialAuthState);

  const didInitialise = useRef(false);

  const auth0 = useAuth0();
  const session = useSession();

  useEffect(() => {
    if (didInitialise.current) {
      return;
    }

    if (!auth0.isLoading && !session.isLoading) {
      didInitialise.current = true;
      if (auth0.isAuthenticated) {
        const user: User = {
          id: auth0.user?.sub || '',
          // Auth0 users only have a given_name and family_name if they have signed in via an identity provider e.g. Google otherwise they will have a name property that is their email address
          firstName: auth0.user?.given_name || auth0.user?.name || '',
          lastName: auth0.user?.family_name || '',
          email: auth0.user?.email || '',
          imageUrl: auth0.user?.picture,
          organizationName: auth0.user?.org_name,
          organizationId: auth0.user?.[import.meta.env.VITE_AUTH0_NAMESPACE + 'internal_org_id'],
          managerId: auth0.user?.[import.meta.env.VITE_AUTH0_NAMESPACE + 'internal_manager_id'],
          surferId: auth0.user?.[import.meta.env.VITE_AUTH0_NAMESPACE + 'internal_surfer_id'],
          roles: auth0.user?.[import.meta.env.VITE_AUTH0_NAMESPACE + 'roles'],
          isDialpad: false,
          isProxied: false,
        };
        setState({
          error: undefined,
          isAuthenticated: auth0.isAuthenticated,
          isLoading: false,
          user: user,
          authMethod: 'auth0',
        });
      } else if (session.isAuthenticated) {
        setState({
          error: undefined,
          isAuthenticated: session.isAuthenticated,
          isLoading: false,
          user: session.user,
          authMethod: 'dialpad',
        });
      } else {
        if (!!auth0.error || !!session.error) {
          setState({
            error: auth0.error || session.error,
            isAuthenticated: false,
            isLoading: false,
            user: undefined,
            authMethod: undefined,
          });
        } else {
          setState({
            error: undefined,
            isAuthenticated: false,
            isLoading: false,
            user: undefined,
            authMethod: undefined,
          });
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [auth0.isLoading, session.isLoading]);

  const exchangeAuthCodeForToken = useCallback(async () => {
    try {
      if (validateOAuthState()) {
        setState(state => ({ ...state, isLoading: true }));
        const response = await fetch(constructServerUrl(`auth/token${window.location.search}`), {
          method: 'GET',
        });

        if (!response.ok) {
          if (response.status === 401) {
            throw new UnauthorizedError(
              'No account found',
              'There is no WFM account associated with your login details. Please try again.',
            );
          }
          throw new Error(`Error: ${response.statusText}`);
        }
        const loggedInRes = await session.checkLoginState();
        setState({
          error: undefined,
          isAuthenticated: !!loggedInRes?.loggedIn,
          isLoading: false,
          user: loggedInRes?.user,
          authMethod: 'dialpad',
        });
      } else {
        throw new Error('OAuth state mismatch');
      }
    } catch (error) {
      setState(state => ({ ...state, isLoading: false, error }));
    }
  }, [session]);

  const logout = useCallback(async () => {
    if (state.authMethod === 'auth0') {
      await auth0.logout({ logoutParams: { returnTo: window.location.origin } });
    } else if (state.authMethod === 'dialpad') {
      await session.logout();
    }
    setState({
      error: undefined,
      isAuthenticated: false,
      isLoading: false,
      user: undefined,
      authMethod: undefined,
    });
  }, [state, auth0, session]);

  const contextValue = useMemo<AuthContextInterface>(() => {
    return {
      ...state,
      logout,
      auth0: {
        getAccessTokenSilently: auth0.getAccessTokenSilently,
        loginWithRedirect: auth0.loginWithRedirect,
      },
      session: {
        checkLoginState: session.checkLoginState,
        exchangeAuthCodeForToken,
      },
    };
  }, [state, logout, auth0, session, exchangeAuthCodeForToken]);

  return <AuthContext.Provider value={contextValue}>{children}</AuthContext.Provider>;
};

export const useAuth = () => {
  const authContext = useContext(AuthContext);

  if (!authContext) {
    throw new Error('useAuth has to be used within AuthProvider');
  }
  return authContext;
};

export const AuthProvider = ({ children }: { children: ReactNode }) => {
  return (
    <Auth0Provider
      useRefreshTokens={true}
      useRefreshTokensFallback={true}
      cacheLocation="localstorage" // so it stays logged in when refreshing on Safari
      domain={import.meta.env.VITE_AUTH0_DOMAIN}
      clientId={import.meta.env.VITE_AUTH0_CLIENT}
      authorizationParams={{
        redirect_uri: window.location.origin,
        audience: import.meta.env.VITE_AUTH0_AUDIENCE, // the backend API in Auth0
      }}
      skipRedirectCallback={window.location.pathname === SharedRoutes.DIALPAD_AUTH_CALLBACK} // this prevents Auth0 from intercepting our callback from Dialpad
    >
      <CustomAuthProvider>{children}</CustomAuthProvider>
    </Auth0Provider>
  );
};

// Dialpad users have user sessions stored in the backend
const useSession = () => {
  const [state, setState] = useState<AuthState>(initialAuthState);
  const didInitialise = useRef(false);

  const checkLoginState = useCallback(async () => {
    try {
      const response = await fetch(constructServerUrl('auth/logged_in'), {
        headers: {
          'Cache-Control': 'no-store',
        },
      });

      if (!response.ok) {
        throw new Error(`Error: ${response.statusText}`);
      }

      const result = GetLoggedInResponseSchema.safeParse(await response.json());

      if (!result.success) {
        throw new Error('Client-side zod validation error in AuthProvider');
      }

      setState({
        error: undefined,
        isAuthenticated: result.data.loggedIn,
        isLoading: false,
        user: result.data.user,
      });

      return result.data;
    } catch (error) {
      setState(state => ({ ...state, isLoading: false, error }));
    }
  }, []);

  const logout = useCallback(async () => {
    try {
      const response = await fetch(constructServerUrl('auth/logout'), {
        method: 'POST',
      });
      if (!response.ok) {
        throw new Error(`Error: ${response.statusText}`);
      }
      setState({
        error: undefined,
        isAuthenticated: false,
        isLoading: false,
        user: undefined,
      });
    } catch (error) {
      setState(state => ({ ...state, isLoading: false, error }));
    }
  }, []);

  useEffect(() => {
    if (didInitialise.current) {
      return;
    }
    didInitialise.current = true;
    (async (): Promise<void> => {
      await checkLoginState();
    })();
  }, [checkLoginState]);

  return {
    ...state,
    checkLoginState,
    logout,
  };
};
