import { Merge } from 'type-fest';
import z from 'zod';

import { COLORS } from './colors';
import {
  BlockedTimeIdSchema,
  CalendarIdSchema,
  ScheduledTaskIdSchema,
  ShiftTemplateIdSchema,
  StreamIdSchema,
  SurferIdSchema,
  TaskIdSchema,
  TaskRef,
  TaskRefSchema,
  TaskTemplateRefSchema,
} from './ids';
import { pagedResultsSchema } from './pagination';
import { PersistedSurferSchema } from './surfers';
import {
  DurationIntervalSchema,
  DurationSchema,
  IntervalSchema,
  NaiveIntervalSchema,
  TimezoneIanaSchema,
  WeekdaySchema,
} from './time';
import { RefSchema, nonnegativeInteger } from './utils';

export const TaskColorSchema = z.enum(COLORS);
export type TaskColor = z.infer<typeof TaskColorSchema>;

const TaskBaseSchema = z.object({
  /**
   * The name of the task.
   */
  name: z.string().min(1),
  /**
   * The emoji representing the task.
   */
  emoji: z.string().min(1),
  /**
   * The kind of task this object represents.
   */
  type: z.string(),
  /**
   * Color used to render the tasks and the scheduled tasks in the UI.
   */
  color: TaskColorSchema,
  /**
   * The activity's state:
   *
   * * `active`: the activity is visible and can be used by the managers.
   * * `archived`: the activity has been soft-deleted. Its data will
   * be kept for historical reference but won't be displayed to managers anymore.
   */
  state: z.enum(['active', 'archived']),
});

/**
 * A task representing an activity that the surfer will perform.
 * Activities are usually tasks that are part of the surfer responsibilities
 * and scheduled using surfboard.
 * Activities are usually tasks that are part of the surfer responsibilities
 * and scheduled using surfboard.
 */
export const ActivitySchema = TaskBaseSchema.extend({
  type: z.literal('task-activity'),
  /**
   * List of surfers skilled to this activity
   */
  surfers: z.array(PersistedSurferSchema.omit({ taskIds: true })),

  /**
   * List of ids for streams (ticket groups) this activity is associated with.
   * Working on this activity counts towards staffing on all listed streams
   */
  streamIds: z.array(StreamIdSchema),
  /**
   * Used to represent whether an activity should show as free or busy on an external calendar.
   * Most activities will be busy.
   */
  isBusy: z.boolean(),
});
export type Activity = z.infer<typeof ActivitySchema>;

/**
 * A break represents a interval of time where the surfer is not expected to work.
 */
export const TaskBreakSchema = TaskBaseSchema.extend({
  type: z.literal('task-break'),
});
export type TaskBreak = z.infer<typeof TaskBreakSchema>;

/**
 * A blocked time is a task assigned to the surfer but not managed
 * by surfboard. They are sync'd using calendar links and can represent
 * any task that was scheduled in a 3rd party service i.e. 1:1s,
 * focus time, stand-up.
 */
export const BlockedTimeSchema = TaskBaseSchema.extend({
  type: z.literal('task-blocked-time'),
});
export type BlockedTime = z.infer<typeof BlockedTimeSchema>;

export const isBlockedTimeTask = (task: ScheduledTask['task']): task is BlockedTime =>
  task.type === 'task-blocked-time';

/**
 * A task describe a kind of action that can be performed by a surfer
 * in a shift.
 *
 * The three main kind of tasks are:
 *
 *  * activities (i.e. phone, email)
 *  * breaks (i.e. lunch, breakfast)
 *  * block time (i.e. standup, 1:1s)
 */
export const CustomTaskSchema = z.discriminatedUnion('type', [ActivitySchema, TaskBreakSchema]);
export type CustomTask = z.infer<typeof CustomTaskSchema>;

export const PersistedCustomTaskSchema = CustomTaskSchema.and(TaskRefSchema);
export type PersistedCustomTask = z.infer<typeof PersistedCustomTaskSchema>;

const PersistedActivitySchema = ActivitySchema.and(TaskRefSchema);
export type PersistedActivity = z.infer<typeof PersistedActivitySchema>;

export const TaskSchema = z.union([PersistedCustomTaskSchema, BlockedTimeSchema]);
export type Task = z.infer<typeof TaskSchema>;

export const isActivity = (input: unknown): input is Activity => {
  return ActivitySchema.safeParse(input).success;
};

export function isServiceActivity(task: PersistedCustomTask) {
  return task.type === 'task-activity' && task.streamIds.length > 0;
}

/**
 * Describes the template used to generate the task.
 */
export const TaskOriginTemplateSchema = z.object({
  type: z.literal('task-origin-template'),
  templateRef: RefSchema,
});
export type TaskOriginTemplate = z.infer<typeof TaskOriginTemplateSchema>;

/**
 * Describes the manual change that scheduled the task.
 */
export const TaskOriginManualSchema = z.object({
  type: z.literal('task-origin-manual'),
});
export type TaskOriginManual = z.infer<typeof TaskOriginManualSchema>;

/**
 * Describes the calendar used as source to plan a task.
 */
export const TaskOriginCalendarSchema = z.object({
  type: z.literal('task-origin-calendar'),
  /**
   * Identifier of the calendar used for syncing the event.
   */
  calendarId: CalendarIdSchema,
  /**
   * Identifier of the event.
   */
  blockedTimeId: BlockedTimeIdSchema,
});
export type TaskOriginCalendar = z.infer<typeof TaskOriginCalendarSchema>;

/**
 * Describes the calendar used as source to plan a task.
 */
export const TaskOriginGCalSchema = z.object({
  type: z.literal('task-origin-gcal'),
  /**
   * Identifier of the Google Calendar used to
   */
  gCalId: z.union([CalendarIdSchema, z.literal('primary')]),
  /**
   * Id of the blocked_time row.
   */
  blockedTimeId: BlockedTimeIdSchema,
});
export type TaskOriginGCal = z.infer<typeof TaskOriginGCalSchema>;

/**
 * All the possible ways to plan a task.
 */
export const TaskOriginSchema = z.discriminatedUnion('type', [
  TaskOriginTemplateSchema,
  TaskOriginManualSchema,
  TaskOriginCalendarSchema,
  TaskOriginGCalSchema,
]);
export type TaskOrigin = z.infer<typeof TaskOriginSchema>;

/**
 * This represents a task scheduled within a shift for a specific surfer.
 */
export const ScheduledTaskSchema = z.object({
  id: ScheduledTaskIdSchema,
  task: z.union([
    BlockedTimeSchema,
    TaskRefSchema.extend({ type: z.enum(['task-activity', 'task-break']) }),
  ]),
  interval: IntervalSchema,
  origin: TaskOriginSchema,
  link: z.string().url().nullish(),
});
export type ScheduledTask = z.infer<typeof ScheduledTaskSchema>;

export type ScheduledActivity = Merge<ScheduledTask, { task: TaskRef & { type: 'task-activity' } }>;
export type ScheduledBreak = Merge<ScheduledTask, { task: TaskRef & { type: 'task-break' } }>;

/**
 * This represents a task at a specific time for a specific surfer.
 */
export const ScheduledTaskWithSurferIdSchema = ScheduledTaskSchema.extend({
  surferId: SurferIdSchema,
});
export type ScheduledTaskWithSurferId = z.infer<typeof ScheduledTaskWithSurferIdSchema>;

export type ScheduledActivityWithSurferId = Merge<
  ScheduledTaskWithSurferId,
  { task: TaskRef & { type: 'task-activity' } }
>;

/**
 * Describes an assign policy where only the surfers
 * specified in the given list can be assigned to this task.
 */
const AssignPolicySpecificSurfersSchema = z.object({
  type: z.literal('assign-policy-surfers'),
  /**
   * All the eligible surfers for this task.
   */
  surferIds: z.array(SurferIdSchema),
});

/**
 * Describes an assign policy where only surfers having
 * the activity in their skills can be assigned to the task.
 */
const AssignPolicySurferSkillsSchema = z.object({
  type: z.literal('assign-policy-surfer-skills'),
});

/**
 * Describes an assign policy where only surfers working
 * on any of the specified shifts can be assigned to the task.
 */
const AssignPolicyShiftsSchema = z.object({
  type: z.literal('assign-policy-shifts'),
  /**
   * IDs of the shift templates that this task can be worked on.
   */
  shiftTemplateIds: z.array(ShiftTemplateIdSchema),
});

/**
 * Describes an assign policy where all surfer are eligible for a task.
 */
const AssignPolicyAllSurfersSchema = z.object({
  type: z.literal('assign-policy-all-surfers'),
});

/**
 * Describes which surfers are eligible for a task.
 *
 * This can be described as:
 * * all surfers.
 * * a fixed list of surfers.
 * * surfers having the required skill.
 * * surfers on specific shifts.
 * *
 */
const AssignPolicySchema = z.discriminatedUnion('type', [
  AssignPolicySpecificSurfersSchema,
  AssignPolicySurferSkillsSchema,
  AssignPolicyShiftsSchema,
  AssignPolicyAllSurfersSchema,
]);
export type AssignPolicy = z.infer<typeof AssignPolicySchema>;
export type AssignPolicySpecificSurfers = z.infer<typeof AssignPolicySpecificSurfersSchema>;
export type AssignPolicyShifts = z.infer<typeof AssignPolicyShiftsSchema>;

export const isAssignPolicySpecificSurfers = (
  input: unknown,
): input is AssignPolicySpecificSurfers => {
  return AssignPolicySpecificSurfersSchema.safeParse(input).success;
};

export const isAssignPolicyShifts = (input: unknown): input is AssignPolicyShifts => {
  return AssignPolicyShiftsSchema.safeParse(input).success;
};

export const SurferConditionSchema = z.object({
  type: z.literal('task-template-condition-surfer'),
  /**
   * Describe how to compare the surfer.
   */
  operator: z.union([z.literal('is'), z.literal('is-not')]),
  /**
   * Condition value.
   */
  surferIds: z.array(SurferIdSchema),
});
export type SurferCondition = z.infer<typeof SurferConditionSchema>;

export const ShiftLengthConditionSchema = z.object({
  type: z.literal('task-template-condition-shift-length'),
  /**
   * Describe how to compare the shift length.
   */
  operator: z.union([z.literal('greater-than'), z.literal('less-than')]),
  /**
   * Condition value.
   */
  duration: nonnegativeInteger,
});
export type ShiftLengthCondition = z.infer<typeof ShiftLengthConditionSchema>;

export const ShiftTemplateConditionSchema = z.object({
  type: z.literal('task-template-condition-shift-template'),
  /**
   * Describe how to compare the shift template.
   */
  operator: z.union([z.literal('is'), z.literal('is-not')]),
  /**
   * IDs of the shift templates that this task can be worked on.
   */
  shiftTemplateIds: z.array(ShiftTemplateIdSchema),
});
export type ShiftTemplateCondition = z.infer<typeof ShiftTemplateConditionSchema>;

export const SurferSkillsConditionSchema = z.object({
  type: z.literal('task-template-condition-surfer-skill'),
  /**
   * Describe how to compare the surfer skills.
   */
  operator: z.union([z.literal('is-any'), z.literal('is-not')]),
  /**
   * IDs of the task the surfer needs to be trained on.
   */
  taskIds: z.array(TaskIdSchema),
});
export type SurferSkillsCondition = z.infer<typeof SurferSkillsConditionSchema>;

export const ScheduledTaskConditionSchema = z.object({
  type: z.literal('task-template-condition-scheduled-task'),
  /**
   * Describe how to compare the scheduled tasks.
   */
  operator: z.union([z.literal('shift-contains'), z.literal('shift-does-not-contain')]),
  /**
   * ID of the tasks the surfer needs to be scheduled on.
   */
  taskIds: z.array(TaskIdSchema),
});
export type ScheduledTaskCondition = z.infer<typeof ScheduledTaskConditionSchema>;

export const RuleConditionSchema = z.discriminatedUnion('type', [
  SurferConditionSchema,
  ShiftLengthConditionSchema,
  ShiftTemplateConditionSchema,
  SurferSkillsConditionSchema,
  ScheduledTaskConditionSchema,
]);
export type RuleCondition = z.infer<typeof RuleConditionSchema>;

/**
 * Base schema for all the activity template rules.
 *
 * The rules describe how a specific task will be scheduled
 * in the available shifts with each type of scheduling
 * having its own structure and set of parameters.
 */
const RuleBaseSchema = z.object({
  type: z.string(),
  taskId: TaskIdSchema,
  name: z.string().optional(),
  /**
   * Condition describing which surfers can be assigned to this task.
   */
  assignPolicy: z.optional(AssignPolicySchema),
  /**
   * All the conditions that need to be met by a shift for tasks
   * to be scheduled on it.
   */
  conditions: z.array(RuleConditionSchema).optional(),
});
export type RuleBase = z.infer<typeof RuleBaseSchema>;

/**
 * A task should be scheduled to start at a fixed time of the day.
 */
const SchedulingFixedTimeSchema = z.object({
  type: z.literal('scheduling-time'),
  /**
   * Time of the day in minutes that indicates when a task should be allocated.
   * Time of the day in minutes that indicates when a task should be allocated.
   *
   * i.e. schedule the Standup task at 9:00 Europe/London
   */
  time: DurationSchema,
  /**
   * Timezone used to localize the scheduling time.
   */
  timezone: TimezoneIanaSchema,
});

/**
 * Interval of the day when a task can be allocated.
 *
 * i.e. schedule the Phone tasks between 10:00 and 15:00.
 */
const SchedulingIntervalSchema = z.object({
  type: z.literal('scheduling-interval'),
  interval: NaiveIntervalSchema,
});

/**
 * Schedule the task at the beginning of the shift.
 */
const SchedulingShiftStartSchema = z.object({
  type: z.literal('scheduling-start-shift'),
});

/**
 * Schedule the task at the end of the shift.
 */
const SchedulingShiftEndSchema = z.object({
  type: z.literal('scheduling-end-shift'),
});

/**
 * Defines in which part of the day the task should be scheduled.
 * Defines in which part of the day the task should be scheduled.
 */
const SchedulingSchema = z.discriminatedUnion('type', [
  SchedulingFixedTimeSchema,
  SchedulingIntervalSchema,
  SchedulingShiftStartSchema,
  SchedulingShiftEndSchema,
]);

/**
 * Describes when to allocate time within a day for a specific task
 * across the selected day.
 */
export const RuleTotalTimeSchedulingTimeSchema = z.object({
  /**
   * Which days of the weeks this tasks should be scheduled on.
   */
  days: z.array(WeekdaySchema),
  /**
   * What part of the day a tasks should be scheduled on.
   */
  time: SchedulingSchema,
});
export type RuleTotalTimeSchedulingTime = z.infer<typeof RuleTotalTimeSchedulingTimeSchema>;

/**
 * Defines how many surfers should be scheduled for a given session.
 */
const SchedulingWindowSessionSchema = z.object({
  /**
   * When the session is happening during the day.
   */
  interval: NaiveIntervalSchema,
  /**
   * How many surfers are expected to be scheduled for this session.
   */
  surfers: nonnegativeInteger,
});

export type SchedulingWindowSession = z.infer<typeof SchedulingWindowSessionSchema>;

/**
 * Describe when a task should be covered and by how many surfers.
 */
const SchedulingWindowSchema = z.object({
  /**
   * Which days of the week this scheduling rule applies to.
   */
  days: z.array(WeekdaySchema),
  /**
   * The interval of the day this scheduling rule applies to.
   */
  interval: NaiveIntervalSchema,
  /**
   * The period at which each coverage window is defined
   */
  granularity: z.number(),
  /**
   * All the fine-granular session describing how many surfers
   * should be scheduled for this task.
   */
  sessions: z.array(SchedulingWindowSessionSchema),
});
export type SchedulingWindow = z.infer<typeof SchedulingWindowSchema>;

const TotalTimeSurferAllocationIndividual = z.object({
  type: z.literal('total-time-allocation-individual'),
});

const TotalTimeSurferAllocationShared = z.object({
  type: z.literal('total-time-allocation-shared'),
  timezone: TimezoneIanaSchema,
});

const TotalTimeSurferAllocationSchema = z.discriminatedUnion('type', [
  TotalTimeSurferAllocationIndividual,
  TotalTimeSurferAllocationShared,
]);
export type TotalTimeSurferAllocation = z.infer<typeof TotalTimeSurferAllocationSchema>;

/**
 * Describes a template rule that schedules surfers in order to have
 * a certain amount of time spent on the selected task.
 *
 * The time can be spread across one or more days
 */
export const RuleTotalTimeSchema = RuleBaseSchema.extend({
  type: z.literal('rule-total-time'),
  /**
   * How long surfers should spend on this task.
   */
  totalTime: nonnegativeInteger,
  /**
   * Indicates if the total time should be spread across each day or a week.
   */
  totalTimeDuration: z.enum(['day', 'week']),
  /**
   * Indicates if the total time should be targeted for each single surfer
   * or spread across all of them.
   *
   * i.e. (individual) Alice and Bob should spend 10 hours each on phones across
   * a week.
   * i.e. (shared) Allocate 10 hours to be spent on phones and split them between
   * Alice an Bob.
   */
  totalTimeSurferAllocation: TotalTimeSurferAllocationSchema,
  /**
   * How long surfers should spend on this activity before switching
   * to another one.
   */
  sessionLength: DurationSchema,
  /**
   * Flag to allow shorter sessions to help maintain coverage around breaks or blocked time.
   */
  isSessionFlex: z.boolean(),
  /**
   * Describes how to allocate the time for this task across a day.
   */
  schedulingTimes: z.array(RuleTotalTimeSchedulingTimeSchema),
});
export type RuleTotalTime = z.infer<typeof RuleTotalTimeSchema>;

/**
 * Describes a template rule that schedules surfers in order to
 * reach a certain coverage for this task across the day.
 *
 * The time can be spread across one or more days
 */
const RuleCoverageSchema = RuleBaseSchema.extend({
  type: z.literal('rule-coverage'),
  /**
   * DEPRECATED use `sessionLengthInterval` instead, setting both `min` and `max` to the same value.
   *
   * How long in minutes surfers should spend on this activity before switching.
   */
  sessionLength: DurationSchema.optional(),
  /**
   * Creates only session with a length between the given interval.
   */
  sessionLengthInterval: DurationIntervalSchema.optional(),
  /**
   * DEPRECATED use `sessionLengthInterval` instead, setting only the `max` field.
   *
   * Flag to allow shorter sessions to help maintain coverage around breaks or blocked time.
   */
  isSessionFlex: z.boolean().optional(),
  /**
   * Flag to allow prioritisation of surfers whose availability best matches the desired activity session length.
   */
  isFindBestMatch: z.preprocess(v => (typeof v === 'boolean' ? v : true), z.boolean()),
  /**
   * When this tasks should be covered and by how many surfers.
   */
  windows: z.array(SchedulingWindowSchema),
  /**
   * DEPRECATED use `sessionLengthInterval` instead, without setting the `max` field.
   *
   * Condition that ignores the session length and prioritizes filling up the full shift
   */
  isFullShift: z.preprocess((v: unknown) => v ?? false, z.boolean()).optional(), // preprocess instead of default because useRequest complains
});

export type RuleCoverage = z.infer<typeof RuleCoverageSchema>;

/**
 * Describe when a full shift task should be allocated and by how many surfers.
 */
const FullShiftWindowSchema = z.object({
  /**
   * Which days of the week this scheduling rule applies to.
   */
  days: z.array(WeekdaySchema),
  /**
   * Which timezone is the day for
   */
  timezone: TimezoneIanaSchema,
  /**
   * How many surfers are expected to be scheduled per day.
   */
  surfers: z.union([nonnegativeInteger, z.literal('all')]),
});

export type FullShiftWindow = z.infer<typeof SchedulingWindowSchema>;

const RuleFullShiftSchema = RuleBaseSchema.extend({
  type: z.literal('rule-full-shift'),

  /**
   * When this tasks should be covered and by how many surfers.
   */
  windows: z.array(FullShiftWindowSchema),
});

export type RuleFullShift = z.infer<typeof RuleFullShiftSchema>;

const RuleForecastSchema = RuleBaseSchema.extend({
  type: z.literal('rule-forecast'),
  /**
   * Creates only session with a length between the given interval.
   */
  sessionLengthInterval: DurationIntervalSchema.optional(),
  /**
   * Allows a user to set what their minimum coverage should be as a percentage of the forecasted
   * staffing requirement
   */
  minimumCoveragePercentage: z.number(),
});
export type RuleForecast = z.infer<typeof RuleForecastSchema>;

export const RuleBreakWindowPosition = z.enum([
  'timeOfDay',
  'timeAfterStartOfShift',
  'timeBeforeEndOfShift',
  'startOfShift',
  'endOfShift',
]);
export type RuleBreakWindowPosition = z.infer<typeof RuleBreakWindowPosition>;

export const RuleBreakWindowPrecision = z.enum(['between', 'exactly']);
export type RuleBreakWindowPrecision = z.infer<typeof RuleBreakWindowPrecision>;

export const RuleBreakWindow = z.object({
  days: z.array(WeekdaySchema),
  /**
   * The interval of the day this scheduling rule applies to.
   */
  interval: NaiveIntervalSchema,
  position: RuleBreakWindowPosition.optional(),
  precision: RuleBreakWindowPrecision.optional(),
});
export type RuleBreakWindow = z.infer<typeof RuleBreakWindow>;

/**
 * Rule used to schedule breaks for surfers.
 */
const RuleBreakSchema = RuleBaseSchema.extend({
  type: z.literal('rule-break'),
  /**
   * How long surfers should have for break.
   */
  sessionLength: DurationSchema,
  /**
   * The window where the breaks should occur.
   */
  windows: RuleBreakWindow.array(),
  /**
   * Evenly stagger breaks across your team by setting
   * the time between each break.
   */
  staggerInterval: DurationSchema,
});
export type RuleBreak = z.infer<typeof RuleBreakSchema>;

/**
 * A rule describes how a specific task is scheduled within a surfer's shift.
 *
 * This can be done in two ways:
 *
 * * total time: aims to have surfers spend a fixed amount of time across a day or week
 * on a specific task.
 * * coverage: aims to have certain number of surfers on a specific task for prefixed
 * sessions during the day.
 */
export const TemplateRuleSchema = z.discriminatedUnion('type', [
  RuleForecastSchema,
  RuleTotalTimeSchema,
  RuleCoverageSchema,
  RuleFullShiftSchema,
  RuleBreakSchema,
]);
export type TemplateRule = z.infer<typeof TemplateRuleSchema>;

export const TaskTemplateColorSchema = z.enum(COLORS);
export type TaskTemplateColor = z.infer<typeof TaskTemplateColorSchema>;

export const TaskTemplateSchema = z.object({
  /**
   * The activity's state:
   *
   * * `active`: the activity is visible and can be used by the managers.
   * * `archived`: the activity has been soft-deleted. Its data will
   * be kept for historical reference but won't be displayed to managers anymore.
   */
  state: z.enum(['active', 'archived']),
  /**
   * List of rules used to populate the schedule each defining how the tasks will be placed
   * in the schedule.
   *
   * The order of the rules defines in which order they are executed making the whole
   * scheduling process deterministic.
   */
  rules: z.array(TemplateRuleSchema),
  /**
   * The name of the task.
   */
  name: z.string().min(1),
  color: TaskTemplateColorSchema,
  /**
   * Multiple templates can be applied on a given day and coexist as long as they apply to different
   * subsets of surfers, listed in this property. An empty array indicates the plan is applied to everyone
   */
  surfers: z.array(SurferIdSchema).optional(),
});
export type TaskTemplate = z.infer<typeof TaskTemplateSchema>;

export const PersistedTaskTemplateSchema = TaskTemplateSchema.and(TaskTemplateRefSchema);
export type PersistedTaskTemplate = z.infer<typeof PersistedTaskTemplateSchema>;

export const GetTasksRespSchema = pagedResultsSchema(PersistedCustomTaskSchema);
export type GetTasksResp = z.infer<typeof GetTasksRespSchema>;

export const GetTaskTemplatesRespSchema = pagedResultsSchema(PersistedTaskTemplateSchema);
export type GetTaskTemplatesResp = z.infer<typeof GetTaskTemplatesRespSchema>;

export const TaskTemplateApplicationSchema = z.object({
  interval: IntervalSchema,
  templateRef: TaskTemplateRefSchema,
});

export const HydratedTaskTemplateApplicationSchema = z.object({
  interval: IntervalSchema,
  template: PersistedTaskTemplateSchema,
});

export type TaskTemplateApplication = z.infer<typeof TaskTemplateApplicationSchema>;
export type HydratedTaskTemplateApplication = z.infer<typeof HydratedTaskTemplateApplicationSchema>;

export const isHydratedTaskTemplateApplication = (
  input: unknown,
): input is HydratedTaskTemplateApplication => {
  return HydratedTaskTemplateApplicationSchema.safeParse(input).success;
};

export const GetTaskTemplateApplicationsRespSchema = z.array(TaskTemplateApplicationSchema);
