import { SystemStyleObject } from '@chakra-ui/theme-tools';

// prettier-ignore
const spacing: SystemStyleObject = {
  0: '0rem', // 0px
  1: '0.125rem', // 2px
  2: '0.25rem',  // 4px
  3: '0.5rem',   // 8px
  4: '0.75rem',  // 12px
  5: '1rem',     // 16px
  6: '1.5rem',   // 24px
  7: '2rem',     // 32px
  8: '2.5rem',   // 40px
  9: '4rem',    // 64px
  10: '5rem',    // 80px
};

export default spacing;
