import { useEffect, useRef } from 'react';
import { useHistory } from 'react-router-dom';

import { useAuth } from '../../providers/AuthProvider';

export const DialpadAuthCallback = () => {
  const called = useRef(false);
  const {
    session: { exchangeAuthCodeForToken },
  } = useAuth();
  const history = useHistory();

  useEffect(() => {
    (async (): Promise<void> => {
      if (called.current) return;
      called.current = true;
      await exchangeAuthCodeForToken();
      history.push('/');
    })();
  }, [exchangeAuthCodeForToken, history]);

  return <></>;
};
