import { defineStyle, defineStyleConfig } from '@chakra-ui/react';

const baseStyle = defineStyle({
  borderRadius: 'md',
  fontWeight: 'semibold',
  _disabled: {
    cursor: 'not-allowed',
  },
  transition: 'all 250ms ease-out',
  // prevent platform-specific styling
  WebkitAppearance: 'none !important',
  MozAppearance: 'none !important',
});

const variantPrimary = defineStyle({
  background: 'var(--action-color-background-base-primary-default, #7C52FF)',
  color: 'var(--action-color-foreground-inverted-default, #FFF)',
  _hover: {
    background: 'var(--action-color-background-base-primary-hover, #7344FF)',
    _disabled: {
      background: 'var(--action-color-background-base-primary-default, #7C52FF)',
      opacity: 0.4,
    },
  },
  _disabled: {
    opacity: 0.4,
  },
});

const variantSecondary = defineStyle({
  border: '1px solid var(--dt-inputs-color-border-default, rgba(0, 0, 0, 0.18))',
  background: 'var(--action-color-background-muted-default, rgba(0, 0, 0, 0.00))',
  color: 'var(--action-color-foreground-muted-default, #3A3A3A)',
  _hover: {
    border: '1px solid var(--dt-inputs-color-border-default, rgba(0, 0, 0, 0.18))',
    background: 'var(--action-color-background-muted-hover, rgba(34, 34, 34, 0.10))',
    color: 'var(--action-color-foreground-muted-hover, #3A3A3A)',
    _disabled: {
      opacity: 0.4,
    },
  },
  _disabled: {
    opacity: 0.4,
  },
});

const variantTertiary = defineStyle({
  color: 'var(--action-color-foreground-muted-default, #3A3A3A)',
  _hover: {
    background: 'var(--action-color-background-muted-hover, rgba(34, 34, 34, 0.10))',
    color: 'var(--action-color-foreground-muted-hover, #3A3A3A)',
    _disabled: {
      opacity: 0.4,
    },
  },
  _disabled: {
    opacity: 0.4,
  },
});

const variantDanger = defineStyle({
  background: 'var(--action-color-background-critical-primary-default, #EC0E0E)',
  color: 'var(--action-color-foreground-inverted-default, #FFF)',
  _hover: {
    background: 'var(--action-color-background-critical-primary-hover, #D90D0D)',
    _disabled: {
      background: 'var(--action-color-background-critical-primary-default, #EC0E0E)',
      opacity: 0.4,
    },
  },
  _disabled: {
    opacity: 0.4,
  },
});

const variantSuccess = defineStyle({
  background: 'var(--action-color-background-positive-primary-default, #1AA340)',
  color: 'var(--action-color-foreground-inverted-default, #FFF)',
  _hover: {
    background: 'var(--action-color-background-positive-primary-hover, #17933A)',
    _disabled: {
      background: 'var(--action-color-background-positive-primary-default, #1AA340)',
      opacity: 0.4,
    },
  },
  _disabled: {
    opacity: 0.4,
  },
});

const variantDropdown = defineStyle({
  fontWeight: 'regular',
  border: '1px solid var(--dt-inputs-color-border-default, rgba(0, 0, 0, 0.18))',
  background: 'var(--dt-inputs-color-background-default)',
  color: 'var(--dt-inputs-color-foreground-default), #3A3A3A)',
  _hover: {
    border: '1px solid var(--inputs-color-border-focus, #51A0FE)',
    _disabled: {
      opacity: 0.4,
    },
  },
  _disabled: {
    background: 'var(--dt-inputs-color-background-disabled)',
    color: 'var(--dt-inputs-color-background-disabled)',
  },
  _focus: {
    background: 'var(--dt-inputs-color-background-focus)',
  },
  _active: {
    border: '1px solid var(--inputs-color-border-focus, #51A0FE)',
  },
});

const variantNavigation = defineStyle({
  ...variantTertiary,
  fontWeight: 'regular',
  _hover: {
    ...variantTertiary._hover,
    _active: {
      background: 'var(--action-color-background-muted-active, rgba(34, 34, 34, 0.16))',
      color: 'var(--action-color-foreground-muted-default, #3A3A3A)',
      fontWeight: 'semibold',
    },
  },
  _active: {
    background: 'var(--action-color-background-muted-active, rgba(34, 34, 34, 0.16))',
    color: 'var(--action-color-foreground-muted-default, #3A3A3A)',
    fontWeight: 'semibold',
  },
});

const variants = {
  primary: variantPrimary,
  secondary: variantSecondary,
  tertiary: variantTertiary,
  danger: variantDanger,
  success: variantSuccess,
  dropdown: variantDropdown,
  navigation: variantNavigation,
};

// TODO: SUR-5170 update to use spacing tokens. For some reason the Chakra defaults are being used instead of our own here.
const sizes = {
  lg: defineStyle({
    h: '40px',
    minW: '40px',
    fontSize: 'f2',
    px: '16px',
  }),
  md: defineStyle({
    h: '32px',
    minW: '32px',
    fontSize: 'f2',
    px: '12px',
  }),
  sm: defineStyle({
    h: '24px',
    minW: '24px',
    fontSize: 'f2',
    px: '8px',
  }),
};

export const buttonTheme = defineStyleConfig({
  baseStyle,
  variants,
  sizes,
  defaultProps: {
    variant: 'tertiary',
    size: 'md',
  },
});
