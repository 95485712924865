import { Center, Divider, HStack, Text, VStack } from '@chakra-ui/react';
import { useHistory } from 'react-router-dom';

import { Button, ButtonBaseProps } from '../../design-system/components/Button';
import { Logo } from '../../design-system/components/Logo';
import { useAuth } from '../../providers/AuthProvider';
import { SharedRoutes } from '../../routes/appRoutes';

export const sharedButtonProps: ButtonBaseProps = {
  borderRadius: '40px',
  minHeight: '54px',
  width: '300px',
};

export const LoginPage = () => {
  const history = useHistory();
  const { isAuthenticated } = useAuth();

  if (isAuthenticated) {
    history.push('/');
  }

  return (
    <Center h="100vh">
      <VStack spacing={7} width="300px">
        <VStack spacing={1}>
          <Logo size="md" />
          <Text
            color={'gray.40'}
            textAlign="center"
            fontSize="15px"
            fontWeight={400}
            lineHeight={'160%'}
          >
            Forecast, Schedule & Manage
          </Text>
        </VStack>
        <VStack spacing={5}>
          <Button
            variant="primary"
            newStyle
            as="a"
            href={SharedRoutes.LOGIN_AUTH0}
            {...sharedButtonProps}
          >
            Continue
          </Button>
          <HStack alignSelf="stretch">
            <Divider color={'gray.30'} />
            <Text
              flexShrink={0}
              color={'gray.30'}
              textAlign="center"
              fontSize="12px"
              fontWeight={400}
              lineHeight="140%"
              textTransform="uppercase"
            >
              Dialpad Customer?
            </Text>
            <Divider color={'gray.30'} />
          </HStack>
          <Button
            variant="secondary"
            newStyle
            as="a"
            href={SharedRoutes.LOGIN_DIALPAD}
            {...sharedButtonProps}
          >
            Continue with Dialpad
          </Button>
        </VStack>
      </VStack>
    </Center>
  );
};
