import { z } from 'zod';

import { OrganizationIdSchema } from './ids';
import { TimezoneIanaSchema } from './time';

export const OrganizationSchema = z.object({
  id: OrganizationIdSchema,
  name: z.string(),
  displayName: z.string().nullable(),
  externalId: z.string().nullable(), // Auth0 organization id e.g. org_w0vCo11Ai8n2LzNN
  createdAt: z.date().nullable(),
  updatedAt: z.date().nullable(),
  timezone: TimezoneIanaSchema,
  dialpadCompanyId: z.string().nullable(),
  dialpadLicenses: z.number().nullable(),
});
export type Organization = z.infer<typeof OrganizationSchema>;
