import { tabsAnatomy } from '@chakra-ui/anatomy';
import { createMultiStyleConfigHelpers } from '@chakra-ui/react';

const { defineMultiStyleConfig, definePartsStyle } = createMultiStyleConfigHelpers(
  tabsAnatomy.keys,
);

const defaultProps = {
  size: 'sm',
};

const baseStyle = definePartsStyle({
  tab: {
    fontWeight: '400',
    // padding is being overridden, so increase specificity
    '&': {
      py: '3',
    },
    _selected: {
      color: 'purple.30',
      borderColor: 'purple.30',
      fontWeight: 'medium',
    },
  },
  tablist: {
    '&': {
      borderBottomWidth: '1px',
      color: 'gray.40',
    },
  },
});

export const tabsTheme = defineMultiStyleConfig({ baseStyle, defaultProps });
