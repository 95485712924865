import { z } from 'zod';

import { StreamIdSchema } from './ids';
import { dateString } from './time';

const ForecastHistoryItem = z.object({
  date: dateString,
  streamId: StreamIdSchema,
  streamName: z.string(),
  forecasted: z.number(),
  actuals: z.number(),
  staffingRequirements: z.number(),
  actualStaffingRequirements: z.number(),
});
export type ForecastHistoryItem = z.infer<typeof ForecastHistoryItem>;

export const ForecastHistorySchema = z.array(ForecastHistoryItem);
export type ForecastHistorySchema = z.infer<typeof ForecastHistorySchema>;
