import { Image, ImageProps } from '@chakra-ui/react';
import React from 'react';

import LogoSVG from '../../../images/dialpad-wfm-logo.svg';

const sizeMap = {
  sm: '24px',
  md: '48px',
} as const;

type LogoSize = keyof typeof sizeMap;

interface LogoProps extends ImageProps {
  size?: LogoSize;
}

export const Logo: React.FC<LogoProps> = ({ size = 'md', ...props }) => (
  <Image src={LogoSVG} alt="Dialpad WFM Logo" height={sizeMap[size]} width="auto" {...props} />
);
