import { z } from 'zod';

import { IntervalSchema, TimezoneIanaSchema } from './time';

export const UtcDateSchema = z.date().brand<'UtcDate'>();
export type UtcDate = z.infer<typeof UtcDateSchema>;

export const ZonedDateSchema = z.object({
  timezone: TimezoneIanaSchema,
  utc: UtcDateSchema,
  zoned: z.date(),
});

export const UtcIntervalSchema = z.object({ start: UtcDateSchema, end: UtcDateSchema });
export type UtcInterval = z.infer<typeof UtcIntervalSchema>;

export const ZonedIntervalSchema = z.object({
  start: ZonedDateSchema,
  end: ZonedDateSchema,
  utcInterval: UtcIntervalSchema,
  zonedInterval: IntervalSchema,
});
