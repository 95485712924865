// eslint-disable-next-line no-restricted-imports
import { Spinner as ChakraSpinner, SpinnerProps } from '@chakra-ui/react';

export const Spinner: React.FC<React.PropsWithChildren<SpinnerProps>> = ({ ...rest }) => (
  <ChakraSpinner
    thickness={'4px'}
    emptyColor="gray.20"
    color="purple.30"
    size="lg"
    label="Loading..."
    {...rest}
  />
);
