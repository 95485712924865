import { inputAnatomy } from '@chakra-ui/anatomy';
import { createMultiStyleConfigHelpers } from '@chakra-ui/react';

const { definePartsStyle, defineMultiStyleConfig } = createMultiStyleConfigHelpers(
  inputAnatomy.keys,
);

const baseStyle = definePartsStyle({
  field: {
    height: '32px',
    border: '1px solid',
    borderRadius: 'md',
    color: 'var(--dt-inputs-color-foreground-default)',
  },
  addon: {
    color: 'var(--dt-inputs-color-foreground-default)',
  },
});

const outline = definePartsStyle({
  field: {
    borderColor: 'var(--dt-inputs-color-border-default)',
    background: 'var(--dt-inputs-color-background-default)',
    _hover: {
      borderColor: 'var(--dt-inputs-color-border-focus)',
    },
    _active: {
      borderColor: 'var(--dt-inputs-color-border-focus)',
    },
    _focus: {
      borderColor: 'var(--dt-inputs-color-border-focus)',
      boxShadow: 'none',
    },
    _invalid: {
      borderColor: 'var(--dt-color-border-critical)',
      boxShadow: 'none',
    },
    _disabled: {
      color: 'var(--dt-inputs-color-foreground-disabled)',
    },
  },
  addon: {
    backgroundColor: 'white',
  },
});

export const inputTheme = defineMultiStyleConfig({
  baseStyle,
  variants: {
    outline: outline,
  },
  defaultProps: {
    variant: 'outline',
  },
});
