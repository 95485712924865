import { RedirectLoginOptions } from '@auth0/auth0-react';
import { useEffect, useMemo } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

import { useAuth } from '../../providers/AuthProvider';
import { CenteredSpinner } from '../../shared-components/CenteredSpinner';

export const Auth0Redirect = () => {
  const {
    authMethod,
    isAuthenticated,
    logout,
    auth0: { loginWithRedirect },
  } = useAuth();
  const history = useHistory();
  const location = useLocation();

  const loginParams = useMemo(() => {
    const query = new URLSearchParams(location.search);
    const params: RedirectLoginOptions = {
      authorizationParams: {},
    };
    params.authorizationParams = params.authorizationParams || {};

    if (query.get('invitation')) {
      params.authorizationParams.invitation = query.get('invitation') || undefined;
    }
    if (query.get('organization')) {
      params.authorizationParams.organization = query.get('organization') || undefined;
    }

    return params;
  }, [location.search]);

  useEffect(() => {
    const redirectUser = async () => {
      if (isAuthenticated) {
        if (authMethod === 'auth0') {
          history.push('/');
        } else if (authMethod === 'dialpad') {
          await logout(); // Logout of Dialpad if we are authenticated there
          await loginWithRedirect(loginParams);
        }
      } else {
        await loginWithRedirect(loginParams);
      }
    };
    redirectUser();
  }, [isAuthenticated, authMethod, history, loginParams, loginWithRedirect, logout]);

  return <CenteredSpinner />;
};
