import { z } from 'zod';

import { RoleSchema } from './auth';
import { DialpadOfficeIdSchema, DialpadUserIdSchema } from './ids';
import { pagedResultsSchema } from './pagination';

export const OfficeSchema = z.object({
  id: DialpadOfficeIdSchema,
  name: z.string(),
});

export const CreateDialpadAgentSchema = z.object({
  id: DialpadUserIdSchema,
  type: z.literal('dialpad'),
});

// minimum agent object used to return values to the frontend, we are limiting the amount of info served
export const AgentSchema = z.object({
  id: DialpadUserIdSchema,
  firstName: z.string(),
  timezone: z.string(),
  lastName: z.string(),
  email: z.string().email().nullish(),
  roles: z.array(RoleSchema),
});

export const LicenceSchemaResp = z.object({
  availableLicenses: z.number(),
});

export const IsDialpadOrgSchema = z.object({
  isDialpadOrg: z.boolean(),
});

export type IsDialpadOrgResp = z.infer<typeof IsDialpadOrgSchema>;

export const GetAgentsRespSchema = pagedResultsSchema(AgentSchema);

export type GetAgentsResp = z.infer<typeof GetAgentsRespSchema>;

export type Agent = z.infer<typeof AgentSchema>;

export const GetLicensesRespSchema = z.object({
  availableLicenses: z.number(),
  totalLicenses: z.number(),
});

export type GetLicensesResp = z.infer<typeof GetLicensesRespSchema>;

export const GetOfficesRespSchema = pagedResultsSchema(OfficeSchema);

export type GetOfficesResp = z.infer<typeof GetOfficesRespSchema>;

export type OfficeResp = z.infer<typeof OfficeSchema>;
