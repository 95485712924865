import { useAuth } from '../providers/AuthProvider';

// TODO: SUR-4912 replace hook with one that will work for Dialpad proxied users
export const useIsSurfboardEmployee = (): boolean => {
  const { user } = useAuth();

  const email = user?.email ?? '';

  return email.endsWith('@teamsurfboard.com');
};
