import { toCamel } from 'convert-keys';
import { z } from 'zod';

import { UserLoginSchema } from './auth0';
import { CreateDialpadAgentSchema } from './dialpad';
import { DialpadOfficeIdSchema, DialpadUserIdSchema, SurferIdSchema, TaskIdSchema } from './ids';
import { NaiveIntervalSchema, TimezoneIanaSchema, WeekdaySchema, dateString } from './time';
import { arrayQueryPreprocessor, nonnegativeNumber } from './utils';

const WorkingWindowSchema = z.object({
  days: z.array(WeekdaySchema),
  interval: NaiveIntervalSchema,
});
export type WorkingWindow = z.infer<typeof WorkingWindowSchema>;

export const SurferSchema = z.object({
  firstName: z.string().min(1),
  lastName: z.string().min(1),
  email: z.string().email(),
  timezone: TimezoneIanaSchema,
  taskIds: TaskIdSchema.array(),
  profilePictureUrl: z.string().url().nullish(),
  deletedAt: dateString.nullish(),
  isConnectedToGCal: z.boolean().optional(),
  startDate: dateString.nullish(),
  endDate: dateString.nullish(),
  scheduledInvitation: z.boolean().nullish(),
  offboarded: z.boolean().optional(),
  minContractedHours: nonnegativeNumber, // weekly
  maxContractedHours: nonnegativeNumber, // weekly
  minContractedDailyMinutes: nonnegativeNumber.optional(),
  maxContractedDailyMinutes: nonnegativeNumber.optional(),
  breakMinutesPerShift: nonnegativeNumber.optional(),
  restMinutesBetweenShifts: nonnegativeNumber.optional(),
  workingWindows: WorkingWindowSchema.array().optional(),
  dialpadUserId: DialpadUserIdSchema.nullish(),
  dialpadOfficeId: DialpadOfficeIdSchema.optional(),
});
export type Surfer = z.infer<typeof SurferSchema>;

export const PersistedSurferSchema = SurferSchema.extend({
  id: SurferIdSchema,
});
export type PersistedSurfer = z.infer<typeof PersistedSurferSchema>;

export const GetSurfersRespSchema = PersistedSurferSchema.array();
export const GetSurferRespSchema = PersistedSurferSchema;
export type GetSurfersResp = z.infer<typeof GetSurfersRespSchema>;
export type GetSurferResp = z.infer<typeof GetSurferRespSchema>;

export const CreateSurferRespSchema = z.object({ surferId: SurferIdSchema });
export type CreateSurferResp = z.infer<typeof CreateSurferRespSchema>;

export const PatchSurferReqSchema = SurferSchema.partial().pick({ profilePictureUrl: true });
export type PatchSurferReq = z.infer<typeof PatchSurferReqSchema>;

export const NudgeSurferReqSchema = z.object({
  message: z.string().min(1),
});
export type NudgeSurferReq = z.infer<typeof NudgeSurferReqSchema>;

export const UpdatePersistedSurferSchema = PersistedSurferSchema.extend({
  removeBlocksAfterEndDate: z.boolean(),
  removeBlocksBeforeStartDate: z.boolean(),
});

export const UpdateSurferReqSchema = z.preprocess(
  input => toCamel(input as object),
  UpdatePersistedSurferSchema,
);
export type UpdateSurferReq = z.infer<typeof UpdateSurferReqSchema>;

export const UpdateSurfersReqSchema = z
  .preprocess(input => toCamel(input as object), PersistedSurferSchema)
  .array();
export type UpdateSurfersReq = z.infer<typeof UpdateSurfersReqSchema>;

export const SurfersQuerySchema = z.object({
  surfers: z.undefined().or(z.preprocess(arrayQueryPreprocessor, SurferIdSchema.array())),
});

export const DuplicatedEmailResponseSchema = z.object({
  error: z.literal('DUPLICATED_EMAIL'),
});
export type DuplicatedEmailResponse = z.infer<typeof DuplicatedEmailResponseSchema>;

export const GetSurferLoginsRespSchema = UserLoginSchema.array();
export type GetSurferLoginsResp = z.infer<typeof GetSurferLoginsRespSchema>;

const CreateStandaloneSurfer = SurferSchema.extend({
  type: z.literal('standalone'),
});

export const CreateSurferSchema = z.discriminatedUnion('type', [
  CreateDialpadAgentSchema,
  CreateStandaloneSurfer,
]);

export type CreateSurferReq = z.infer<typeof CreateSurferSchema>;
