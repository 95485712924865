export const fonts = {
  body: 'var(--dt-font-family-body)',
  heading: 'var(--dt-font-family-body)',
  mono: 'var(--dt-font-family-mono)',
};

export const fontSizes = {
  f1: '0.781rem',
  f2: '0.875rem',
  f3: '1.125rem',
  f4: '1.375rem',
  f5: '2rem',
  f6: '2.625rem',
  f7: '3.375rem',
  f8: '4.25rem',
};

export const fontWeights = {
  hairline: 100,
  thin: 200,
  light: 300,
  normal: 400,
  medium: 500,
  semibold: 600,
  bold: 700,
  extrabold: 800,
  black: 900,
};

export const lineHeights = {
  paragraph: 1.45,
  heading: 1.45,
  compact: 1.15,
};
