import { OAuthError } from '@auth0/auth0-react';
import { Center, Text, VStack } from '@chakra-ui/react';

import { Button } from '../../design-system/components/Button';
import { Logo } from '../../design-system/components/Logo';
import { useAuth } from '../../providers/AuthProvider';
import { UnauthorizedError } from '../../queries/api';
import { sharedButtonProps } from './LoginPage';

const useErrorDetails = (error: Error): { type: string; description: string } => {
  const defaultType = 'Unknown';
  const defaultDescription = error.message;

  const location = window.location;
  const queryString = new URLSearchParams(location.search);

  let type = defaultType;
  let description = defaultDescription;

  if (error instanceof UnauthorizedError) {
    type = error.error;
    description = error.error_description ?? defaultDescription;
  } else {
    const oauthError = error as OAuthError;
    type = oauthError.error ?? queryString.get('error') ?? defaultType;
    description =
      oauthError.error_description ?? queryString.get('error_description') ?? defaultDescription;
  }

  return { type, description };
};

export const AuthErrorPage = ({ error }: { error: Error }) => {
  const { logout } = useAuth();
  const { type, description } = useErrorDetails(error);

  return (
    <Center h="100vh">
      <VStack spacing={7} width="300px">
        <Logo size="md" />
        <VStack spacing={1}>
          <Text
            color={'red.error'}
            textAlign="center"
            fontSize="19px"
            fontWeight={510}
            lineHeight={'160%'}
          >
            {type}
          </Text>
          <Text
            color={'red.error'}
            textAlign="center"
            fontSize="15px"
            fontWeight={400}
            lineHeight={'160%'}
          >
            {description}
          </Text>
        </VStack>
        <Button variant="secondary" newStyle onClick={logout} {...sharedButtonProps}>
          Log in again
        </Button>
      </VStack>
    </Center>
  );
};
