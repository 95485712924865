import { z } from 'zod';

import { SurferIdSchema } from './ids';
import { BlockIdSchema } from './schedule';
import { dateString } from './time';

const TIMECARD_TYPES = ['standard', 'manual', 'overtime'] as const;

export const ShiftTimecardSchema = z.object({
  id: z.number(),
  type: z.enum(TIMECARD_TYPES),
  surferId: SurferIdSchema,
  receivedStart: dateString,
  receivedEnd: dateString.nullable(),
  reportedStart: dateString,
  reportedEnd: dateString.nullable(),
  scheduledStart: dateString.nullable(),
  scheduledEnd: dateString.nullable(),
  blockIdAtStart: BlockIdSchema.nullable(),
  blockIdAtEnd: BlockIdSchema.nullable(),
});

export type ShiftTimecard = z.infer<typeof ShiftTimecardSchema>;

export const PersistedShiftTimecardSchema = ShiftTimecardSchema.extend({
  id: z.number(),
});
export type PersistedShiftTimecard = z.infer<typeof PersistedShiftTimecardSchema>;

export const StandardClockInSchema = z.object({
  type: z.literal('standard'),
  scheduledStart: dateString,
  blockIdAtStart: BlockIdSchema,
});
export const ManualClockInSchema = z.object({
  type: z.literal('manual'),
  reportedStart: dateString,
  scheduledStart: dateString,
  blockIdAtStart: BlockIdSchema,
});
export const OvertimeClockInSchema = z.object({
  type: z.literal('overtime'),
  reportedStart: dateString.optional(),
});

export const ClockInSchema = z.discriminatedUnion('type', [
  StandardClockInSchema,
  ManualClockInSchema,
  OvertimeClockInSchema,
]);
export type ClockIn = z.infer<typeof ClockInSchema>;

export const StandardClockOutSchema = z.object({
  type: z.literal('standard'),
  scheduledEnd: dateString,
  blockIdAtEnd: BlockIdSchema,
});
export const ManualClockOutSchema = z.object({
  type: z.literal('manual'),
  reportedEnd: dateString,
  scheduledEnd: dateString,
  blockIdAtEnd: BlockIdSchema,
});
export const OvertimeClockOutSchema = z.object({
  type: z.literal('overtime'),
  reportedEnd: dateString.optional(),
});

export const ClockOutSchema = z.discriminatedUnion('type', [
  StandardClockOutSchema,
  ManualClockOutSchema,
  OvertimeClockOutSchema,
]);
export type ClockOut = z.infer<typeof ClockOutSchema>;

export const GetLatestOpenShiftTimecardRespSchema = z.object({
  value: PersistedShiftTimecardSchema.or(z.null()),
});

export const GetShiftTimecardsRespSchema = z.array(PersistedShiftTimecardSchema);

export const UpdateShiftTimecardSchema = z.object({
  reportedEnd: dateString,
  reportedStart: dateString,
});
