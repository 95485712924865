import { z } from 'zod';

import { SurferAvailabilityIdSchema, SurferId, SurferIdSchema } from './ids';
import { dateString } from './time';
import { ZonedIntervalSchema } from './timezone';

export const SurferAvailabilitySchema = z.object({
  start: dateString,
  end: dateString,
});

export type SurferAvailability = z.infer<typeof SurferAvailabilitySchema>;

export const PersistedSurferAvailabilitySchema = SurferAvailabilitySchema.extend({
  id: SurferAvailabilityIdSchema,
  surferId: SurferIdSchema,
});
export type PersistedSurferAvailability = z.infer<typeof PersistedSurferAvailabilitySchema>;

export const GetAvailabilityRespSchema = PersistedSurferAvailabilitySchema.array();
export type GetAvailabilityResp = z.infer<typeof GetAvailabilityRespSchema>;

export const CreateSurferAvailabilityReqSchema = SurferAvailabilitySchema;
export type CreateSurferAvailabilityReq = z.infer<typeof CreateSurferAvailabilityReqSchema>;

export const UpdateSurferAvailabilityReqSchema = PersistedSurferAvailabilitySchema;
export type UpdateSurferAvailabilityReq = z.infer<typeof UpdateSurferAvailabilityReqSchema>;

export const ZonedAvailabilitySchema = PersistedSurferAvailabilitySchema.pick({
  id: true,
  surferId: true,
}).extend({
  interval: ZonedIntervalSchema,
  displayInterval: ZonedIntervalSchema.optional(), // Represents the interval to display after taking into account any overlapping shifts.
  blockType: z.literal('availability-block'),
});
export type ZonedAvailability = z.infer<typeof ZonedAvailabilitySchema>;

export type AvailabilityBySurferId = { [key: SurferId]: PersistedSurferAvailability[] };
export type ZonedAvailabilityBySurferId = { [key: SurferId]: ZonedAvailability[] };
